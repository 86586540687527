<template>

  <div class="mt-1">

    <Menu></Menu>

    <Slider></Slider>

    <div class="body">

      <div v-show="!view_more" class="bets-header <!--row-->  flex-row flex-nowrap">

          <div class="btn btn-sm col-auto bold" v-bind:class="selectedClass(-10)" @click="setFilter(-10)">
            All <span v-show="all > 0" v-text="all" v-bind:class="getCounterClass(-10)"></span>
          </div>

          <div v-show="pending > 0" class="btn btn-sm col-auto bold" v-bind:class="selectedClass(0)" @click="setFilter(0)">
              Pending <span v-show="pending > 0" v-text="pending" v-bind:class="getCounterClass(0)"></span>
          </div>

          <div v-show="won > 0" class="btn btn-sm col-auto bold" v-bind:class="selectedClass(2)" @click="setFilter(2)">
              Won <span v-show="won > 0" v-text="won" v-bind:class="getCounterClass(2)"></span>
          </div>

          <div v-show="voided > 0" class="btn btn-sm col-auto bold" v-bind:class="selectedClass(3)" @click="setFilter(3)">
              Void <span v-show="voided > 0" v-text="voided" v-bind:class="getCounterClass(3)"></span>
          </div>
<!--
          <div v-show="cancelled > 0" class="btn btn-sm col-auto bold" v-bind:class="selectedClass(4)" @click="setFilter(4)">
            Cancelled <span v-show="cancelled > 0" v-text="cancelled" v-bind:class="getCounterClass(4)"></span>
          </div>
        -->

      </div>

      <div v-show="!view_more" class="">

        <div class="tab-content" id="myTabContent">

          <div v-for="(b,index) in filteredBets" v-bind:key="index">

            <div class="mb-1 bets-item" v-bind:class="b.borderClass">

              <div class="row" @click="viewBet(b)">

                <div class="col-6">

                  <pre v-show="b.filter_status === 2" class="black">
                    <lazy-image class="lozad" v-bind:src="getURL('/assets/images/i-check-won.png')" height="15" style="margin-bottom: 5px;height: 15px;" /> Bet ID {{ b.code}}
                  </pre>

                  <pre class="black">
                    <span class="bets-item-status" v-bind:class="b.betLabelClass" v-text="b.statusName"></span>
                    Bet ID {{ b.code}}
                  </pre>

                  <pre class="bold black">
                    <span>
                      {{ formatMatchDate(b.created) }}
                    </span>
                  </pre>
                </div>

                <div class="col-6 text-right">
                  <pre class="black" style="font-weight: var(--font-weight-bold);" v-if="b.bet_type == 4">
                    {{ 250000 | currency }}
                  </pre>
                  <pre class="black" style="font-weight: var(--font-weight-bold);" v-else>
                    {{ b.possible_win | currency }}
                  </pre>
                  <div>
                      <span style="color: #8c8c8c;font-weight: bold; font-size: 0.8em" v-if="b.bet_type == 4">stake {{ 15 | currency }} </span>
                      <span style="color: #8c8c8c;font-weight: bold; font-size: 0.8em" v-else>stake {{ b.stake | currency }} </span>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

      <div v-if="view_more" v-bind:class="loading">

        <div class="bd-callout bd-callout-default" style="padding-bottom: 9px;border: 0;">

          <span class="left">
              <span @click="viewAll" class="v-m">
                <arrow-left-icon></arrow-left-icon>
              </span>
          </span>

          <p class="right">
            <span v-text="statusName"></span>

            Bet ID: {{ bet.code }}

          </p>

          <div class="row d-flex justify-content-between w-100" style="padding: 10px 0px 0px 10px;">

            <div class="col-auto b-label bold" v-if="bet.bet_type != 4">
              Total Odds {{ bet.total_odd | formatOdds }}
            </div>

            <div class="col-auto b-label bold" v-if="bet.bet_type == 4">
              Stake {{ 15 | currency }}
            </div>

              <div class="col-auto b-label bold" v-else>
              Stake {{ bet.stake | currency }}
            </div>

            <div class="col-auto b-label bold" v-if="bet.bet_type == 4">
              Date {{ formatMatchDate(bet.created) }}
            </div>

            <div v-show="parseInt(filter_status) === 2" class="col-auto b-label bold" v-if="bet.bet_type == 4">
              Won {{ 250000 | currency }}
            </div>

              <div v-show="parseInt(filter_status) === 2" class="col-auto b-label bold" v-else>
              Won {{ bet.possible_win | currency }}
            </div>

            <div v-show="parseInt(filter_status) !== 2" class="col-auto b-label bold" v-if="bet.bet_type == 4">
              Possible Win {{ 250000 | currency }}
            </div>

              <div v-show="parseInt(filter_status) !== 2" class="col-auto b-label bold" v-else>
              Possible Win {{ bet.possible_win | currency }}
            </div>

          </div>

        </div>

        <pre></pre>

        <div v-for="(p,index) in bet.picked" v-bind:key="index">

          <div class="bd-callout" v-bind:class="getBorderClass(p)">

            <div class="row betslip">

              <div class="col-4 text-center">
                  <pre class="black">
                    {{ getHomeCompetitorName(p.match_name) }}
                  </pre>
              </div>

              <div class="col-4 text-center">
                <pre class="black">
                  <span v-show="p.fixture_status"></span>
                  <span v-show="!p.fixture_status">No Results</span>
                </pre>
              </div>

              <div class="col-4 text-center">
                <pre class="black">
                  {{ getAwayCompetitorName(p.match_name) }}
                </pre>
              </div>

            </div>

            <div class="row">

              <div class="col-6">
                  <p class="left betslip-details">Type
                    <span class="right" v-text="p.market_name"></span>
                  </p>
              </div>

              <div class="col-6">
                  <p class="left betslip-details">
                    FT Results
                    <span class="right" v-if="p.fixture_status">
                      <span v-if="parseInt(p.status) === 0 ">- : -</span>
                      <span v-else>{{ p.fixture_status.home_score }} : {{ p.fixture_status.away_score }}</span>
                    </span>
                  </p>
              </div>

              <div class="col-6">
                  <p class="left betslip-details">
                    Pick
                    <span class="right">
                      {{ p.outcome_name }} ({{ p.odd | formatOdds }})
                    </span>
                  </p>
              </div>

              <div class="col-6">
                  <p class="left betslip-details">
                    Outcome
                    <span class="right">
                      <span class="bets-item-status" v-bind:class="getBetLabelClass(p)" v-text="getStatusName(p)"></span>
                    </span>
                  </p>
              </div>

            </div>

          </div>

        </div>

        <pre></pre>

        <div v-show="parseInt(bet.status) === 0" class="bd-callout">

          <div class="row betslip" style="margin-bottom: 1em">

            <div class="col-12 text-center">

              <div class="copy-betslip-btn">

                <router-link class=""
                    :to="{ name: 'share', params: { code: bet.code}}">
                  Copy to Betslip
                </router-link>

              </div>

            </div>

          </div>

        </div>

        <div v-show="parseInt(bet.status) === 0" class="bd-callout">

          <div class="row betslip" style="margin-bottom: 1em">

            <div class="col-12 text-center">
              <h3 style="color: var(--primary)">Share Bet</h3>
              <div>Share this bet with friends</div>
            </div>

            <div class="col-12 text-center">

              <div class="copy-betslip-btn" @click="shareBet(bet.code,bet.total_odd)">
                Share Bet
              </div>

            </div>

          </div>

        </div>

      </div>

      <BottomFooter></BottomFooter>

      <bottom-navigation active-bets="0" page="setting"></bottom-navigation>

    </div>

  </div>

</template>

<script>

import BottomFooter from './BottomFooter'
import Menu from './Menu'
import Slider from './Slider'
import BottomNavigation from './BottomNavigation'

import axios from "@/services/api";

export default {
  name: 'Bets',
  components: {
    Menu,
    BottomFooter,
    BottomNavigation,
    Slider
  },
  data: function () {
    return {
      amount: 0,
      loading: '',
      bet: {},
      bets:[],
      view_more: false,
      filter_status:  0, // 0 - all, 1 - active , 2 - won , 3 - lost , 4 - cancelled,
      all: 0,
      pending: 0,
      won: 0,
      lost: 0,
      voided: 0,
      cancelled: 0
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function() {
        // react to route changes...
        document.title = "My Bets";
        document.description = "Manage Bets and Results";
      }
    },
  },
  mounted() {
    this.$store.dispatch("setCurrentPage","bets");
    this.myBets();
    this.reloadProfile();
    this.setFilter(-10)
  },
  methods: {
    getBetFilterStatus: function (bet){

      switch (parseInt(bet.status)) {

        case 0:
          return 'Pending';

        case 1:
          return 'Lost';

        case 2:
          return 'Won';

        case 3:
          return 'Voided';

        case 4:
          return 'Cancelled';
      }
    },
    cancelBet: function(bet){

      this.reset();
      var vm = this;
      var path = process.env.VUE_APP_URL_CANCEL_BET;

      var data = {
        bet_id: bet.id
      };

      this.loading = 'loading';

      axios.post(path, JSON.stringify(data))
          .then(res => {
            vm.loading = '';
            var msg = res.data.message;
            vm.setSuccess("Success", msg);

          })
          .catch(err => {

            vm.loading = '';

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },
    shareBet: function(code,odds){

      var vm = this;
      var ob = {
        code: code,
        odds: odds
      };

      console.log(JSON.stringify(ob));
      vm.EventBus.$emit('share:bet',ob);

    },
    getCounterClass: function(status){

      if(parseInt(status) === parseInt(this.filter_status)) {

        return 'bets-counter-selected';
      }

      return 'bets-counter-unselected';

    },
    getHomeCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }

      return matchName.split('vs.')[0];
    },
    getAwayCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }
      return matchName.split('vs.')[1];
    },
    getMatchClass: function (picked) {

      if(parseInt(picked.status) === 0 ) {

        return "bd-callout-pending";

      }

      if(parseInt(picked.status) === 1 ) {

        return "bd-callout-pending";

      }

      if(parseInt(picked.status) === -1) {

        return "bd-callout-cancel";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {

        return "bd-callout-won";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 0)  {

        return "bd-callout-lost";

      }
    },
    viewAll: function () {

      this.view_more = false;

    },
    setFilter: function (filter_status) {

      this.filter_status = filter_status

    },
    getMyBetLabelClass: function (bet){

      switch (parseInt(bet.filter_status)) {

        case 0:
          return 'bet-text-pending';

        case 1:
          return 'bet-text-lost';

        case 2:
          return 'bet-text-won';

        case 3:
          return 'bet-text-voided';

        case 4:
          return 'bet-text-cancelled';
      }

      return '';

    },
    getBetStatusName: function (bet){

      switch (parseInt(bet.filter_status)) {

        case 0:
          return 'Pending';

        case 1:
          return 'Lost';

        case 2:
          return 'Won';

        case 3:
          return 'Voided';

        case 4:
          return 'Cancelled';
      }

      return '';

    },
    getBetsBorderClass: function (bet){

      switch (parseInt(bet.filter_status)) {

        case 0:
          return 'bets-item-pending';

        case 1:
          return 'bets-item-lost';

        case 2:
          return 'bets-item-won';

        case 3:
          return 'bets-item-voided';

        case 4:
          return 'bets-item-cancelled';
      }

      return '';

    },

    myBets: function () {

      this.reset();
      var p = this.getProfile();
      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_BETS.replace("{profile_id}", p.d);

      var data = {};

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.message;
            vm.bets = msg;

            vm.all = vm.bets.length;

            vm.jQuery.each(vm.bets,function(k,v){

              var filter_status = parseInt(v.processing_status);
              v.filter_status = filter_status;
              v.statusName = vm.getBetStatusName(v);
              v.betLabelClass = vm.getMyBetLabelClass(v);
              v.borderClass = vm.getBetsBorderClass(v);

              switch (parseInt(v.processing_status)) {

                case 0:
                  vm.pending++
                      break

                case 1:
                  vm.lost++
                  break

                case 2:
                  vm.won++
                  break

                case 3:
                  vm.voided++
                  break

                case 4:
                  vm.cancelled++
                  break
              }

            });

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;
              } else {

                vm.setError("Failed", err.response.data.message)
                console.log(JSON.stringify(err.response.data.message));
              }

            }
            else if (err.request) {

              console.log(JSON.stringify(err.request));

            }
            else {

              console.log(JSON.stringify(err));

            }

          })
    },
    selectedClass: function (filter_status) {

      return this.filter_status === filter_status ? 'bets-tab-text btn-selected': 'bets-tab-text btn-normal';

    },
    viewBet: function(bet) {

      this.view_more = true;

      if(typeof bet.fixture_status === undefined || bet.fixture_status === undefined ) {

        bet.fixture_status = {};
        bet.fixture_status.home_score = '-';
        bet.fixture_status.away_score = '-';
      }

      this.bet = bet;

    },
    getBorderClass: function (picked){

      if(parseInt(picked.status) === 0 ) {

        return "bets-item-pending";

      }

      if(parseInt(picked.status) === 1 ) {

        return "bets-item-pending";

      }

      if(parseInt(picked.status) === -1) {

        return "bets-item-cancel";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {

        return "bets-item-won";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 0)  {

        return "bets-item-lost";

      }

      return '';

    },
    getStatusName: function (picked){

      if(parseInt(picked.status) === 0 ) {

        return "Pending";

      }

      if(parseInt(picked.status) === 1 ) {

        return "Pending";

      }

      if(parseInt(picked.status) === -1) {

        return "Cancelled";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {

        return "Won";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 0)  {

        return "Lost";

      }

      return '';

    },
    getBetLabelClass: function (picked){

      if(parseInt(picked.status) === 0 ) {

        return "bet-text-pending";

      }

      if(parseInt(picked.status) === 1 ) {

        return "bet-text-pending";

      }

      if(parseInt(picked.status) === -1) {

        return "bet-text-cancel";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 1) {

        return "bet-text-won";
      }

      if(parseInt(picked.status) === 2 && parseInt(picked.won) === 0)  {

        return "bet-text-lost";

      }

      return '';

    },
  },
  computed: {
    profile: function() {
      return this.getProfile();
    },
    filteredBets: function () {
      var vm = this;

      if(vm.filter_status === -10){

        return this.bets;
      }

      var bets = [];
      this.jQuery.each(this.bets,function(k,v){

        if(parseInt(v.processing_status) === parseInt(vm.filter_status)) {

          bets.push(v)
        }
      });

      return bets;
    },
    borderClass: function (){

      switch (parseInt(this.filter_status)) {

        case 0:
          return 'bets-item-pending';

        case 1:
          return 'bets-item-lost';

        case 2:
          return 'bets-item-won';

        case 3:
          return 'bets-item-voided';

        case 4:
          return 'bets-item-cancelled';
      }

      return '';

    },
    statusName: function (){

      switch (parseInt(this.filter_status)) {

        case 0:
          return 'Pending';

        case 1:
          return 'Lost';

        case 2:
          return 'Won';

        case 3:
          return 'Voided';

        case 4:
          return 'Cancelled';
      }

      return '';

    },
    betLabelClass: function (){

      switch (parseInt(this.filter_status)) {

        case 0:
          return 'bet-text-pending';

        case 1:
          return 'bet-text-lost';

        case 2:
          return 'bet-text-won';

        case 3:
          return 'bet-text-voided';

        case 4:
          return 'bet-text-cancelled';
      }

      return '';

    },
  },
  filters: {
    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
}
</script>